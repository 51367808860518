import MonoRail from '@mindful-web/marko-web-theme-monorail/browser';
import Leaders from '@mindful-web/marko-web-leaders/browser';

const DynamicSiteMenuPositioner = () => import(/* webpackChunkName: "dynamic-site-menu-positioner" */ './dynamic-site-menu-positioner.vue');
const GAMTopLeaderboardLeaveBehind = () => import(/* webpackChunkName: "top-leaderboard-leave-behind" */ './top-leaderboard-leave-behind.vue');

export default (Browser) => {
  MonoRail(Browser);
  Leaders(Browser);
  Browser.register('DynamicSiteMenuPositioner', DynamicSiteMenuPositioner);
  Browser.register('GAMTopLeaderboardLeaveBehind', GAMTopLeaderboardLeaveBehind);
};
